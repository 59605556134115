( function( $ ) {
	
	$(document).ready(function(){
		
		var imageGridRotatorOptions = {
			slidesToShow: 5,
			arrows: true,
			centerMode: true,
			centerPadding: '0px',
			lazyLoad: 'ondemand',
			responsive: [
				{
			  	breakpoint: 1366,
			  	settings: {
					slidesToShow: 4,
			  	}
				},
				{
			  	breakpoint: 1024,
			  	settings: {
					slidesToShow: 3,
			  	}
				},
				{
			  	breakpoint: 820,
			  	settings: {
					slidesToShow: 2,
			  	}
				},
				{
			  	breakpoint: 660,
			  	settings: {
					slidesToShow: 1,
					adaptiveHeight: true,
					centerMode: false,
			  	}
				},
		  	],
		};

		
		if($('html').attr('dir') == 'rtl'){
			imageGridRotatorOptions['rtl'] = true;
			//testimonialRotatorOptions['rtl'] = true;
		}
		
		/*
		$('.block--small-image-grid.block-style--rotator .item-list').slick(imageGridRotatorOptions);	
		$('.block--testimonials.block-style--rotator .item-list').slick(testimonialRotatorOptions);
		*/
		
		$('.block--image_collection .item-list.list-style--slider').each(function(){
			
			$(this).slick(imageGridRotatorOptions);
			
		});
		
		
		$('.block--testimonials .item-list.list-style--slider').each(function(){
			
			var testimonialRotatorOptions = {
				slidesToShow: 1,
				slidesToScroll: 1,
				arrows: true,
				adaptiveHeight: true,
				lazyLoad: 'ondemand',	
				//arrowsPlacement: 'afterSlides',
				//speed: 200,
			};
			
			/*
			if( $(this).hasClass('slider-orientation--vertical') ){
				var testimonialRotatorOptions = {
					slidesToShow: 1,
					slidesToScroll: 1,
					vertical: true,
					verticalSwiping: true,
					//centerMode: true,
					arrows: true,
					adaptiveHeight: true,
					//centerPadding: "10px",
					
					responsive: [
						{
						  breakpoint: 660,
						  settings: {
							  adaptiveHeight: true,
							slidesToShow: 1,
							slidesToScroll: 1,
						  }
						}
					],
					
				};
			}
			*/
			
			/*
			
			if( $(this).hasClass('slider-orientation--horizontal') ){
				
				if( $(this).hasClass('column-count--two_col') ){
					var testimonialRotatorOptions = {
						slidesToShow: 2,
						slidesToScroll: 1,
						arrows: true,
						responsive: [
							{
							  breakpoint: 660,
							  settings: {
								  adaptiveHeight: true,
								slidesToShow: 1,
								slidesToScroll: 1,
							  }
							}
						],
					};
				}
				
				if( $(this).hasClass('column-count--three_col') ){
					var testimonialRotatorOptions = {
						slidesToShow: 3,
						slidesToScroll: 1,
						arrows: true,
						adaptiveHeight: true,
						responsive: [
							{
							  breakpoint: 1024,
							  settings: {
								slidesToShow: 2,
								slidesToScroll: 1,
							  }
							},
							{
							  breakpoint: 660,
							  settings: {
								slidesToShow: 1,
								slidesToScroll: 1,
							  }
							}
						],
					};
				}
				
			}
			*/
			

			
			if($('html').attr('dir') == 'rtl'){
				testimonialRotatorOptions['rtl'] = true;
			}
			
			$(this).slick(testimonialRotatorOptions);
			
		});
		
		
		
		var galleryOptions = {
			slidesToShow: 1,
			slidesToScroll: 1,
			infinite: false,
			arrows: true,
			fade: true,
			//asNavFor: '.gallery-nav',
			adaptiveHeight: true,
			lazyLoad: 'ondemand',
			instructionsText: 'Changing this current slide of this carousel will change the current slide of the thumbnail carousel that follows.',
			regionLabel: 'main image carousel',
		}
		
		var galleryNavOptions = {
			slidesToShow: 5,
			slidesToScroll: 5,
			//asNavFor: '.block--image_collection .item-list.list-style--gallery',
			infinite: false,
			arrows: true,
			//dots: true,
			lazyLoad: 'ondemand',
			swipe: false,
  		  	instructionsText: 'Changing the current slide of this carousel will change the current slide of the preceding main image carousel.',
			regionLabel: 'thumbnail carousel',
			responsive: [
				{
				  breakpoint: 820,
				  settings: {
					slidesToShow: 4,
					slidesToScroll: 4,
				  }
				},
				{
				  breakpoint: 660,
				  settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
				  }
				}
			],
		}
		
		$('.block--image_collection .item-list.list-style--gallery').each(function(){
			
			var blockID = $(this).closest('.block--image_collection').attr('id');
			
			//galleryOptions['asNavFor'] = '#'+blockID+' .gallery-nav';
			//galleryNavOptions['asNavFor'] = '#'+blockID+' .item-list.list-style--gallery';
			
			$('#'+blockID+' .item-list.list-style--gallery').slick(galleryOptions);
			$('#'+blockID+' .gallery-nav').slick(galleryNavOptions);
			
			// Update the thumbnail slider when the user changes the main slider directly.
			$('#'+blockID+' .item-list.list-style--gallery').on('beforeChange', function(e, slider, currentSlide, nextSlide) {
			  // Remove aria-current from the last selected thumbnail image button
			  $('#'+blockID+' .gallery-nav button.gallery-nav__thumbnail[aria-current="true"]').removeAttr('aria-current');
			  
			  // Select the thumbnail image button that goes with this main image. Most importantly, this updates Slick's internal state to be consistent with the visual change.
			  $('#'+blockID+' .gallery-nav').slick('slickGoTo', nextSlide);
			
			  // Add aria-current="true" to the correct thumbnail image button to convey to screen readers that it's active.
			  $('#'+blockID+' .gallery-nav button.gallery-nav__thumbnail:eq(' + nextSlide + ')').attr('aria-current', true);
			}); 
			
		});
		
		$('.gallery-nav .slick-slide').on('click', function(e){
			
			var slideIndex = $(this).attr('data-slick-index');
			var currentSlideIndex = $(this).closest('.gallery-nav').slick('slickCurrentSlide');
			var blockID = $(this).closest('.block--image_collection').attr('id');
			
			
			//console.log(slideIndex);
			//console.log(currentSlideIndex);
			
			
			if(slideIndex != currentSlideIndex){
				$('#'+blockID+' .item-list.list-style--gallery').slick('slickGoTo', slideIndex, false);
				//$(this).closest('.gallery-nav').slick('slickGoTo', slideIndex, false);
				
				$('#'+blockID+' .gallery-nav button.gallery-nav__thumbnail').removeAttr('aria-current');
				$(this).find('button').attr('aria-current', true);
			}
			

			
			//$(this).closest('.gallery-nav').css('border', '5px solid red');
			
			//$(this).closest('.gallery-nav').slick('slickGoTo', slideIndex, false).slick('setPosition');
			
		});
		

			
			
	});
	
	
} )( jQuery );