( function( $ ) {
	$(document).ready(function() {
		$(".promotion .button").click(function (e) {
			//console.log('The function is hooked up');
			//e.preventDefault();
			$.ajax({
				type: "POST",
				url: "/wp-admin/admin-ajax.php",
				data: {
					action: 'mxw_promotions_increase_popularity',
					// add your parameters here
					//message_id: $('.your-selector').val()
					post_id: $(this).attr('data-promotion-id')
				},
				success: function (output) {
					//console.log(output);
					//console.log('shoulda happened');
				}
			});
		});
	});
} )( jQuery );