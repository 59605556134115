( function( $ ) {
	/*
	$('.page__sharing__toggle').on('click', function(e){
		$('.page__sharing__links').toggle().parents('.page__sharing').toggleClass('is-open');
	})
	*/
	
	var observer = new IntersectionObserver(function(entries) {
		// isIntersecting is true when element and viewport are overlapping
		// isIntersecting is false when element and viewport don't overlap

		
		if(entries[0].isIntersecting === false){
			//console.log('not in viewport');
			$('.page__sharing__links').addClass('is-visible');
		}
		
		if(entries[0].isIntersecting === true){
			//console.log('Element has just become visible in screen');
			$('.page__sharing__links').removeClass('is-visible');
			
		}
			
	}, { 
		threshold: [0],
		rootMargin: "-80px 0px -80px 0px"
	});
	
	observer.observe(document.querySelector("#page-header"));
	
	console.log('hi');
	
	
} )( jQuery );