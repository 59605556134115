// https://www.w3.org/WAI/tutorials/menus/flyout/
// modified for supporting multiple levels
// modified to include icon/tigger support
// modified to add/remove classes rather than outright replacing all classes

// Keyboard Navigation Support
var menuItems = document.querySelectorAll('li.has-submenu');
Array.prototype.forEach.call(menuItems, function(el, i){
	var activatingA = el.querySelector('a');
	var visualTrigger = '<span class="ic-ui_chevron-down"></span>';
	var btn = '<button><span><span class="visuallyhidden">show submenu for “' + activatingA.text + '”</span>' + visualTrigger +'</span></button>';
	activatingA.insertAdjacentHTML('afterend', btn);

	el.querySelector('button').addEventListener("click",  function(event){
		if (this.parentNode.classList.contains('has-submenu') && !this.parentNode.classList.contains('open')) {
			this.parentNode.classList.add('open');
			this.parentNode.querySelector('a').setAttribute('aria-expanded', "true");
			this.parentNode.querySelector('button').setAttribute('aria-expanded', "true");
		} else {
			this.parentNode.classList.remove('open');
			this.parentNode.querySelector('a').setAttribute('aria-expanded', "false");
			this.parentNode.querySelector('button').setAttribute('aria-expanded', "false");
		}
		event.preventDefault();
	});
	
	el.addEventListener('focusout', function(event){	
		if(!el.contains(event.relatedTarget)){
			el.querySelector('a').setAttribute('aria-expanded', "false");
			el.querySelector('button').setAttribute('aria-expanded', "false");
			el.classList.remove('open');
		}

	});
	
});

// Mouse Navigation Support

/*

var menuItems = document.querySelectorAll('li.has-submenu');
Array.prototype.forEach.call(menuItems, function(el, i){
	el.addEventListener("mouseover", function(event){
		this.classList.add('open');
		if(typeof timer === 'number'){
			clearTimeout(timer);
		}
	});
	el.addEventListener("mouseout", function(event){
		timer = setTimeout(function(event){
			el.classList.remove('open');
		}, 1000);
	});
});

*/
