( function( $ ) {

	$(document).ready(function(){
		

		

		
		
	});
	
	//$(".embed-container").fitVids();
	
	//iFrameResize({ log: true }, '.embed-container iframe');
	
	var offCanvasOptions = {
		modifiers: 'right, overlay', // default options
		triggerButton: '#triggerButton' // btn to open offcanvas
	}
	
	if($('html').attr('dir') == 'rtl'){
		offCanvasOptions['modifiers'] = 'left, overlay';
	}
	
	
	
	
	$('#offCanvas').offcanvas(offCanvasOptions);
	
	var primary_nav_off_canvas = $('#offCanvas').data('offcanvas-component');
	
	$('#offcanvas--main-menu__close').on('click', function(e){
		e.preventDefault();
		primary_nav_off_canvas.close();
	});
	

} )( jQuery );
